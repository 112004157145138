export let BANKSTATEMENT_API_URL = "";
export let LOANS_API_URL = "";
export let DOC_CLASSIFICATION_API_URL = "";

if (typeof window !== "undefined") {
  switch (window.location.hostname) {
    case "localhost":
      BANKSTATEMENT_API_URL = "https://bankincomedev.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-dev.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://localhost:7090/";
      break;

    case "qa.morgej.com":
    case "www.qa.morgej.com":
      BANKSTATEMENT_API_URL = "https://bankincomeqa.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-qa.azurewebsites.net/";
      break;

    case "dev.morgej.com":
    case "www.dev.morgej.com":
      BANKSTATEMENT_API_URL = "https://bankincomedev.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-dev.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-dev.azurewebsites.net/";
      break;

    case "bsic.usdemo.msuite.ai":
    case "www.bsic.usdemo.msuite.ai":
      BANKSTATEMENT_API_URL = "https://us-bankbackend-demo.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      break;

    case "bsic.indemo.msuite.ai":
    case "www.bsic.indemo.msuite.ai":
      BANKSTATEMENT_API_URL = "https://bankbackend-demo.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      break;

    case "bsic.uat.msuite.ai":
    case "www.bsic.uat.msuite.ai":
      BANKSTATEMENT_API_URL = "https://bankbackend-uat.azurewebsites.net/";
      break;

    default:
      BANKSTATEMENT_API_URL = "https://bankincomeqa.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
  }
}

export const environment = {
  production: false,
  doc_classification_api_url: DOC_CLASSIFICATION_API_URL,
  backstatement_api_url: BANKSTATEMENT_API_URL,
  loans_api_url: LOANS_API_URL,
  msalConfig: {
    auth: {
      clientId: "7d9b204f-da2c-4cda-b214-7d55a6e3bd5a",
    },
  },
  apiConfig: [
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Read",
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Write",
      ],
      uri: "https://msuitecommon-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: ["https://flatworldsolb2c.onmicrosoft.com/bsic/BSIC.Read"],
      uri: BANKSTATEMENT_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Read",
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Write",
      ],
      uri: "https://msuitecustomer-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Read",
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Write",
      ],
      uri: "https://blob-service-dev.azurewebsites.net/api/v2",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Write",
      ],
      uri: "https://msuiteuser-dev.azurewebsites.net/api/v1",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Read",
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Write",
      ],
      uri: LOANS_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Write",
      ],
      uri: "https://rulesenginevalidator-dev.azurewebsites.net/api/",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Documents/Document.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Documents/Document.Write",
      ],
      uri: "https://msuiteppa.azurewebsites.net/",
    },
  ],
  defaultScope: ["https://flatworldsolb2c.onmicrosoft.com/api/user.read"],
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1_Signin",
      resetPassword: "B2C_1_reset",
      editProfile: "B2C_1_edit_profile",
    },
    authorities: {
      signUpSignIn: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_Signin",
      },
      resetPassword: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_reset",
      },
      editProfile: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_edit_profile",
      },
    },
    authorityDomain: "flatworldsolb2c.b2clogin.com",
  },
};
