import { ActionType, createAction, props } from "@ngrx/store";
import { ICondition, IDocumentList } from "./data-extraction.types";

export const GET_DOCUMENTS_LIST = "GET_DOCUMENTS_LIST";
export const GET_DOCUMENTS_LIST_SUCCESS = "GET_DOCUMENTS_LIST_SUCCESS";
export const GET_DOCUMENTS_LIST_FAILURE = "GET_DOCUMENTS_LIST_FAILURE";

export const GetDocumentTypeList = createAction(GET_DOCUMENTS_LIST, props<{ loanId: string }>());
export const GetDocumentTypeListSuccess = createAction(GET_DOCUMENTS_LIST_SUCCESS, props<{ data: IDocumentList[] }>());
export const GetDocumentTypeListFailure = createAction(
  GET_DOCUMENTS_LIST_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GET_CONDITIONS = "GET_CONDITIONS";
export const GET_CONDITIONS_SUCCESS = "GET_CONDITIONS_SUCCESS";
export const GET_CONDITIONS_FAILURE = "GET_CONDITIONS_FAILURE";

export const GetConditions = createAction(GET_CONDITIONS, props<{ loanId: string }>());
export const GetConditionsSuccess = createAction(
  GET_CONDITIONS_SUCCESS,
  props<{ data: ICondition[]; message: string }>()
);
export const GetConditionsFailure = createAction(
  GET_CONDITIONS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const FETCH_FILE_BY_ID = "FETCH_FILE_BY_ID";
export const FETCH_FILE_BY_ID_SUCCESS = "FETCH_FILE_BY_ID_SUCCESS";
export const FETCH_FILE_BY_ID_FAILURE = "FETCH_FILE_BY_ID_FAILURE";

export const FetchFileById = createAction(FETCH_FILE_BY_ID, props<{ loanId: string; fileId: string }>());
export const FetchFileByIdSuccess = createAction(FETCH_FILE_BY_ID_SUCCESS, props<{ data: string }>());
export const FetchFileByIdFailure = createAction(
  FETCH_FILE_BY_ID_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const FETCH_MULTIPLE_FILES = "FETCH_MULTIPLE_FILES";
export const FETCH_MULTIPLE_FILES_SUCCESS = "FETCH_MULTIPLE_FILES_SUCCESS";
export const FETCH_MULTIPLE_FILES_FAILURE = "FETCH_MULTIPLE_FILES_FAILURE";

export const FetchMultipleFiles = createAction(
  FETCH_MULTIPLE_FILES,
  props<{ loanId: string; fileIds: string[] }>() 
);

export const FetchMultipleFilesSuccess = createAction(
  FETCH_MULTIPLE_FILES_SUCCESS,
  props<{ files: { fileId: string, base64: string }[], errors: { fileId: string, error: string }[] }>()
);

export const FetchMultipleFilesFailure = createAction(
  FETCH_MULTIPLE_FILES_FAILURE,
  props<{ errors?: string[]; message: string }>()
);


export type DataExtractionActions =
  | ActionType<typeof GetDocumentTypeList>
  | ActionType<typeof GetDocumentTypeListSuccess>
  | ActionType<typeof GetDocumentTypeListFailure>
  | ActionType<typeof GetConditions>
  | ActionType<typeof GetConditionsSuccess>
  | ActionType<typeof GetConditionsFailure>
  | ActionType<typeof FetchFileById>
  | ActionType<typeof FetchFileByIdSuccess>
  | ActionType<typeof FetchFileByIdFailure>
  | ActionType<typeof FetchMultipleFiles>
  | ActionType<typeof FetchMultipleFilesSuccess>
  | ActionType<typeof FetchMultipleFilesFailure>;;
