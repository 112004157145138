import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { StatementTransactionServiceService } from "src/app/service/StatementTransactionService/statement-transaction-service.service";
import {
  GetTransactionDetails,
  GetTransactionDetailsFailure,
  GetTransactionDetailsSuccess,
  GetTransactionStatementPeriodDetails,
  GetTransactionStatementPeriodDetailsFailure,
  GetTransactionStatementPeriodDetailsSuccess,
  ProcessCategories,
  ProcessCategoriesFailure,
  ProcessCategoriesSuccess,
  ProcessTransactionFlag,
  ProcessTransactionFlagFailure,
  ProcessTransactionFlagSuccess,
  ReallocateTransaction,
  ReallocateTransactionFailure,
  ReallocateTransactionSuccess,
  ReValidateSummary,
  ReValidateSummaryFailure,
  ReValidateSummarySuccess,
  SaveValidateStatementTransaction,
  SaveValidateStatementTransactionFailure,
  SaveValidateStatementTransactionSuccess,
} from "./transaction.actions";

@Injectable()
export class ExtractionEffects {
  constructor(
    private actions$: Actions,
    private transactionService: StatementTransactionServiceService
  ) {}

  GetTransactionDetailsBySummaryId = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTransactionDetails),
      mergeMap((action) =>
        this.transactionService.getTransactionDetails(action.loanId, action.summaryId).pipe(
          map((response) => {
            if (response.success) {
              return GetTransactionDetailsSuccess({ data: response.data });
            } else {
              return GetTransactionDetailsFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(GetTransactionDetailsFailure({ message: error })))
        )
      )
    )
  );

  GetTransactionStatementPeriodDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTransactionStatementPeriodDetails),
      mergeMap((action) =>
        this.transactionService.getStatementSummaryDetails(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetTransactionStatementPeriodDetailsSuccess({ data: response.data });
            } else {
              return GetTransactionStatementPeriodDetailsFailure({
                errors: response.errors,
                message: response.message,
              });
            }
          }),
          catchError((error) => of(GetTransactionStatementPeriodDetailsFailure({ message: error })))
        )
      )
    )
  );

  SaveValidateStatementTransactionDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveValidateStatementTransaction),
      mergeMap((action) =>
        this.transactionService
          .saveOrValidateTransactionDetails(action.data, action.isValidated, action.loanId, action.summaryId)
          .pipe(
            map((response) => {
              if (response.success) {
                return SaveValidateStatementTransactionSuccess({ data: response.data });
              } else {
                return SaveValidateStatementTransactionFailure({ errors: response.errors, message: response.message });
              }
            }),
            catchError((error) => of(SaveValidateStatementTransactionFailure({ message: error })))
          )
      )
    )
  );

  ReValidateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReValidateSummary),
      mergeMap((action) =>
        this.transactionService.reValidateSummary(action.statementId, action.isValidate).pipe(
          map((response) => {
            if (response.success) {
              return ReValidateSummarySuccess({ message: response.message });
            } else {
              return ReValidateSummaryFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(ReValidateSummaryFailure({ message: error })))
        )
      )
    )
  );

  ProcessTransactionFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessTransactionFlag),
      mergeMap((action) =>
        this.transactionService.processLoanTransactionFlags(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return ProcessTransactionFlagSuccess({ message: response.message });
            } else {
              return ProcessTransactionFlagFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(ProcessTransactionFlagFailure({ message: error })))
        )
      )
    )
  );

  ProcessCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessCategories),
      mergeMap((action) =>
        this.transactionService.processLoanCategory(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return ProcessCategoriesSuccess({ message: response.message });
            } else {
              return ProcessCategoriesFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(ProcessCategoriesFailure({ message: error })))
        )
      )
    )
  );
  ReallocateTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReallocateTransaction),
      mergeMap((action) =>
        this.transactionService.reallocationTransaction(action.data).pipe(
          map((response) => {
            if (response.success) {
              return ReallocateTransactionSuccess({ data: response.data });
            } else {
              return ReallocateTransactionFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(ReallocateTransactionFailure({ message: error })))
        )
      )
    )
  );
}
