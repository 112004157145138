import {
  CLEAR_SUMMARY_STORE,
  GET_DOCUMENT_BY_STATEMENT_ID,
  GET_DOCUMENT_BY_STATEMENT_ID_FAILURE,
  GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS,
  SummaryActions,
} from "./summary.actions";
import { StatementSummaryState } from "./summary.types";

const initialState: StatementSummaryState = {
  documents: { errorMessage: undefined, data: {}, loading: false },
};

let remainingDocuments;

export function summaryReducer(state = initialState, action: SummaryActions): StatementSummaryState {
  switch (action.type) {
    case GET_DOCUMENT_BY_STATEMENT_ID:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: true,
        },
      };
    case GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS:
      return {
        ...state,
        documents: {
          errorMessage: undefined,
          data: {
            ...state.documents.data,
            [action.statementId]: action.data,
          },
          loading: false,
        },
      };

    case GET_DOCUMENT_BY_STATEMENT_ID_FAILURE:
      remainingDocuments = { ...state.documents.data };
      delete remainingDocuments[action.statementId];

      return {
        ...state,
        documents: {
          errorMessage: action.message,
          data: remainingDocuments,
          loading: false,
        },
      };

    case CLEAR_SUMMARY_STORE:
      return initialState;

    default:
      return state;
  }
}
